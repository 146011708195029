import {
  IBotMode,
  ICompleteBotInfo,
  INewClientInfo,
  IPatientResolvedInfo,
  ITerminResolveInfo,
  IPatientenEmailsAndInfos,
  IPatientenEmailsQueryParams,
  IPatientSearchQueryParams,
  IBotPatientSearchResult,
  IBotPatientDetails,
  INotiz,
  IHKPDetails,
  ITerminRohWithDeletedInfo,
  IPatientNameResolverParams,
  IRoseBotPatientHashResult,
} from '../../../../types';
import { RoseApi } from '../apiTypes';

export function createBotApi(roseApi: RoseApi) {
  return {
    resolveNames: (params: IPatientNameResolverParams) =>
      roseApi.collector.post<IPatientResolvedInfo[]>(`/api/names`, params),
    resolveNotizen: (patids: string[]) => roseApi.collector.post<INotiz[]>(`/api/notizen`, { ids: patids }),
    resolveTerminInfos: (patids: string[]) =>
      roseApi.collector.post<ITerminResolveInfo[]>(`/api/termininfos`, { ids: patids }),
    resolveHKPs: (patids: string[]) => roseApi.collector.post<IHKPDetails[]>(`/api/hkps`, { ids: patids }),
    resolveDiagnosen: (date: string, patids: string[]) =>
      roseApi.collector.post<{ patid: string; diagnosen: string[] }[]>(`/api/diagnosen`, { date, patids }),
    bot: () => roseApi.collector.get<ICompleteBotInfo>(`/api/bot`),
    startImport: (incremental: boolean) =>
      roseApi.collector.get<{ message: string }>(`/api/startimport/${incremental}`),
    resetQueue: (incremental: boolean) => roseApi.collector.post<{ message: string }>(`/api/resetqueue/${incremental}`),
    getPatientenEmails: (params: IPatientenEmailsQueryParams) =>
      roseApi.collector.post<IPatientenEmailsAndInfos[]>(`/api/patientenemails`, params),
    searchPatients: (params: IPatientSearchQueryParams) =>
      roseApi.collector.post<IBotPatientSearchResult[]>(`/api/patient/suche`, params),
    getPatientDetails: (patid: string, fakeData = false) =>
      roseApi.collector.post<IBotPatientDetails>(`/api/patient/details`, { patId: patid, fakeData }),
    getLiveTermineUpdate: (patid: string, lastchange?: number) =>
      roseApi.collector.get<ITerminRohWithDeletedInfo[]>(`/api/terminupdate/${patid}`, { query: { lastchange } }),
    updateExternalIp: (cid: string) =>
      roseApi.collector.get<{ ip?: string; success: boolean; error?: string }>(`/api/updateexternalip/${cid}`),
    getPatientHashes: (patids: string[]) =>
      roseApi.collector.post<IRoseBotPatientHashResult>(`/api/hashpatients`, patids),
  };
}

export function createConnectorCheckApi(roseApi: RoseApi) {
  return {
    newClientInfos: (ip?: string) => roseApi.collector.get<INewClientInfo[]>(`/newclientinfos?ip=${ip}`),
    createFirstUser: (cid: string, email: string, mode: IBotMode) =>
      roseApi.collector.post<{ message: string; ok: boolean }>(`/createfirstuser`, { cid, email, mode }),
  };
}

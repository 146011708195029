import { toastStore } from '@/state/toast.state';
import { IAdminDashBoardItem } from '../../../types';

export * from '@/../../common-ui/src/dashboard/helpers';

export const botVersionTimeFormat = 'YYYY-MM-DDTHH:mm';

// empty noop methods as default params
export async function handleApiCall<T = any>({
  apiFunction,
  successMessage = 'Erfolg!',
  errorMessage = 'Fehler!',
  successMessageCallback = null,
  successCallback = (response: T) => {},
  errorCallback = (error: Error) => {},
}: {
  apiFunction: () => Promise<T>;
  successMessage?: string | null;
  successMessageCallback?: ((response: T) => string) | null;
  errorMessage?: string;
  successCallback?: (response: T) => any;
  errorCallback?: (error: Error) => any;
}) {
  try {
    const response = await apiFunction();
    if (successMessage) {
      notifySuccess(successMessage);
    }
    if (successMessageCallback) {
      notifySuccess(successMessageCallback(response));
    }
    successCallback(response);
  } catch (err: any) {
    console.error(err, err.response?.data);
    if (err.response?.data?.error) {
      notifyError(`${errorMessage}: ${err.response.data.error}, ${err.response.data.message}`);
    } else {
      notifyError(errorMessage);
    }
    if (errorCallback) {
      errorCallback(err);
    }
  }
}
export async function handleApiCallWithoutNotify({
  apiFunction,
  successCallback = () => {},
  errorCallback = () => {},
}: {
  apiFunction: () => Promise<any>;
  successMessage?: string | null;
  errorMessage?: string;
  successCallback?: (response: any) => any;
  errorCallback?: (error: Error) => any;
}) {
  try {
    const response = await apiFunction();
    successCallback(response);
  } catch (e) {
    errorCallback(e);
  }
}

export function notifySuccess(successMessage = 'Erfolg!') {
  toastStore.setToast(successMessage);
}

export function notifyError(errorMessage = 'Fehler!') {
  toastStore.setToast(errorMessage);
}

export function licenceCountCssClass(meta: IAdminDashBoardItem, failClass: string, okClass: string) {
  if (meta.anzahlBehandler.za && meta.anzahlBehandler.pzr && meta.stripe?.metricsBehandlerLizenzen) {
    if (meta.anzahlBehandler.za + meta.anzahlBehandler.pzr * 0.5 > meta.stripe.metricsBehandlerLizenzen) {
      return failClass;
    } else {
      return okClass;
    }
  }
  return '';
}

import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('MasterDetailComponent', {
    attrs: {
      "id": "clients"
    },
    scopedSlots: _vm._u([{
      key: "main",
      fn: function fn() {
        return [_c('div', {
          staticClass: "header-bar"
        }, [!_vm.isSolutioTeam ? _c('div', {
          staticClass: "app-filter"
        }, [_c(VBtnToggle, {
          attrs: {
            "mandatory": ""
          },
          model: {
            value: _vm.filterSelect,
            callback: function callback($$v) {
              _vm.filterSelect = $$v;
            },
            expression: "filterSelect"
          }
        }, [_c(VBtn, {
          attrs: {
            "small": "",
            "value": "all"
          }
        }, [_c('div', {
          staticClass: "count-button"
        }, [_c('span', [_vm._v("alle")]), _c('span', {
          staticClass: "bold-count"
        }, [_vm._v(_vm._s(_vm.totals.all))])])]), _c(VBtn, {
          attrs: {
            "small": "",
            "value": "metrics"
          }
        }, [_c('div', {
          staticClass: "count-button"
        }, [_c('span', [_vm._v("metrics")]), _c('span', {
          staticClass: "bold-count"
        }, [_vm._v(_vm._s(_vm.totals.metrics))])])]), _c(VBtn, {
          attrs: {
            "small": "",
            "value": "r4c"
          }
        }, [_c('div', {
          staticClass: "count-button"
        }, [_c('span', [_vm._v("r4c")]), _c('span', {
          staticClass: "bold-count"
        }, [_vm._v(_vm._s(_vm.totals.r4c))])])]), _c(VBtn, {
          staticClass: "no-licenses",
          attrs: {
            "small": "",
            "value": "nolicences"
          }
        }, [_c('div', {
          staticClass: "count-button"
        }, [_c('span', [_vm._v("ohne lizenz")]), _c('span', {
          staticClass: "bold-count"
        }, [_vm._v(_vm._s(_vm.totals.nolicences))])])]), _c(VBtn, {
          staticClass: "problem",
          attrs: {
            "small": "",
            "value": "offline"
          }
        }, [_c('div', {
          staticClass: "count-button"
        }, [_c('span', [_vm._v("offline")]), _c('span', {
          staticClass: "bold-count"
        }, [_vm._v(_vm._s(_vm.totals.offline))])])])], 1)], 1) : _c('div', {
          staticClass: "notting"
        }), !_vm.isSolutioTeam ? _c('div', {
          staticClass: "veraltet-filter"
        }, [_c(VSwitch, {
          staticClass: "mt-1 pt-0 ml-4",
          attrs: {
            "label": "zeige ".concat(_vm.totals.veraltet, " veraltete"),
            "inset": "",
            "hide-details": "",
            "dense": ""
          },
          model: {
            value: _vm.showVeraltet,
            callback: function callback($$v) {
              _vm.showVeraltet = $$v;
            },
            expression: "showVeraltet"
          }
        })], 1) : _c('div', {
          staticClass: "notting"
        }), _c('div', {
          staticClass: "spacing"
        }), _c('div', {
          staticClass: "exports"
        }, [_c(VBtn, {
          attrs: {
            "small": ""
          },
          on: {
            "click": _vm.toggleTableSize
          }
        }, [_vm.tableHeight === _vm.smallTableHeight ? _c(VIcon, [_vm._v("mdi-arrow-expand-all")]) : _c(VIcon, [_vm._v("mdi-arrow-collapse-all")])], 1), _c(VBtn, {
          attrs: {
            "small": "",
            "title": "Export für Externe"
          },
          on: {
            "click": _vm.exportClients
          }
        }, [_c(VIcon, [_vm._v("mdi-table-arrow-right")])], 1), _c(VBtn, {
          attrs: {
            "small": "",
            "color": "error",
            "title": "Export für Intern"
          },
          on: {
            "click": _vm.exportClientsAllColumns
          }
        }, [_c(VIcon, [_vm._v("mdi-table-arrow-right")])], 1)], 1)]), _c(VCard, {
          attrs: {
            "outlined": ""
          }
        }, [_c('ag-grid-vue', {
          staticClass: "client-grid ag-theme-balham",
          attrs: {
            "row-data": _vm.clients,
            "column-defs": _vm.columnDefs,
            "grid-options": _vm.gridOptions,
            "get-row-class": _vm.getRowClass,
            "context": _vm.gridContext,
            "rowSelection": "single"
          },
          on: {
            "selection-changed": _vm.onSelectionChanged,
            "grid-ready": _vm.onGridReady,
            "first-data-rendered": _vm.onFirstDataRendered
          }
        }), _c('div', {
          staticClass: "d-flex flex-wrap justify-space-between align-center ma-1"
        }, [_c('div', {
          staticClass: "d-flex flex-wrap"
        }, _vm._l(_vm.tableFilters, function (g) {
          return _c('div', {
            key: g.name,
            staticClass: "d-flex mr-1"
          }, [_c('ClientFilter', {
            attrs: {
              "filterGroup": g
            },
            on: {
              "filter": _vm.setFilterExpression
            }
          })], 1);
        }), 0), _c('span', {
          staticClass: "overline"
        }, [_vm._v(_vm._s(_vm.countFilteredClients.count) + " / " + _vm._s(_vm.countAllClients.count) + " (" + _vm._s(_vm.countFilteredClients.percentage) + "%)")])])], 1)];
      },
      proxy: true
    }, {
      key: "detail",
      fn: function fn() {
        return [_vm.selectedClient ? _c('div', {
          staticClass: "detail-wrapper"
        }, [_c('div', {
          staticClass: "left-column"
        }, [_c('ClientKunde', {
          attrs: {
            "is-solutio-team": _vm.isSolutioTeam,
            "selected-client": _vm.selectedClient,
            "selected-client-settings": _vm.selectedClientSettings,
            "all-user-tags": _vm.tags
          },
          on: {
            "reload": function reload($event) {
              return _vm.reloadAction(false);
            },
            "handleApiCallWithResponseStatusInfo": _vm.handleApiCallWithResponseStatusInfo
          }
        }), _c('ClientStripe', {
          attrs: {
            "is-solutio-team": _vm.isSolutioTeam,
            "is-dev": _vm.isDev,
            "is-staging": _vm.isStaging,
            "selected-client": _vm.selectedClient
          },
          on: {
            "loadClientData": function loadClientData($event) {
              return _vm.loadClientData(_vm.selectedClient);
            },
            "handleApiCallWithResponseStatusInfo": _vm.handleApiCallWithResponseStatusInfo
          }
        }), _c('ClientOnboardingInfos', {
          attrs: {
            "is-solutio-team": _vm.isSolutioTeam,
            "selected-client": _vm.selectedClient
          },
          on: {
            "handleApiCallWithResponseStatusInfo": _vm.handleApiCallWithResponseStatusInfo
          }
        }), _c('ClientDWH', {
          attrs: {
            "is-solutio-team": _vm.isSolutioTeam,
            "selected-client": _vm.selectedClient
          },
          on: {
            "handleApiCallWithResponseStatusInfo": _vm.handleApiCallWithResponseStatusInfo
          }
        })], 1), _c('div', {
          staticClass: "right-column"
        }, [_c('ClientR4c', {
          attrs: {
            "is-solutio-team": _vm.isSolutioTeam,
            "selected-client": _vm.selectedClient
          },
          on: {
            "handleApiCallWithResponseStatusInfo": _vm.handleApiCallWithResponseStatusInfo
          }
        }), _vm.hasDoctosyncFeature ? _c('ClientDoctosync', {
          attrs: {
            "is-solutio-team": _vm.isSolutioTeam,
            "selected-client": _vm.selectedClient
          },
          on: {
            "handleApiCallWithResponseStatusInfo": _vm.handleApiCallWithResponseStatusInfo
          }
        }) : _vm._e(), _c('ClientHelpdesk', {
          attrs: {
            "is-solutio-team": _vm.isSolutioTeam,
            "selected-client": _vm.selectedClient
          },
          on: {
            "reload": function reload($event) {
              return _vm.reloadAction(false);
            },
            "handleApiCallWithResponseStatusInfo": _vm.handleApiCallWithResponseStatusInfo
          }
        }), _vm.selectedClientBot && _vm.selectedClientSettings ? [_c('BotSettingsCardComponent', {
          attrs: {
            "client-settings-prop": _vm.selectedClientSettings,
            "bot": _vm.selectedClientBot,
            "client-stempel": _vm.clientStempel
          },
          on: {
            "reloadData": _vm.reloadCurrentClient
          }
        })] : _c(VCard, {
          attrs: {
            "outlined": ""
          }
        }, [_c(VCardText, [_vm._v("Kein bot gefunden")])], 1), _c('ClientTrigger', {
          attrs: {
            "is-solutio-team": _vm.isSolutioTeam,
            "selected-client": _vm.selectedClient
          },
          on: {
            "handleApiCallWithResponseStatusInfo": _vm.handleApiCallWithResponseStatusInfo
          }
        }), _c(VCard, {
          staticClass: "activity-chart",
          attrs: {
            "outlined": ""
          }
        }, [_c(VCardTitle, [_c('div', {
          staticClass: "card-title"
        }, [_vm._v("Aktivität")])]), _c(VCardText, [_vm.selectedClient && _vm.selectedClient.meta.clientActivity ? _c('ClientActivityChartComponent', {
          attrs: {
            "chart-data": _vm.selectedClient.meta.clientActivity
          }
        }) : _c('span', [_vm._v("Keine Aktivitätsdaten")])], 1)], 1), _c(VCard, {
          staticClass: "r4c-praxis-info",
          attrs: {
            "outlined": ""
          }
        }, [_c(VCardTitle, [_c('div', {
          staticClass: "card-title"
        }, [_vm._v("r4c Praxis-Info")])]), _c(VCardText, [_vm.selectedClient.r4cpraxis ? _vm._l(_vm.selectedClient.r4cpraxis, function (v, k) {
          return _c('div', {
            key: k,
            staticClass: "info-prop"
          }, [_c('span', [_vm._v(_vm._s(k) + ":")]), _c('span', {
            staticClass: "prop-value"
          }, [_vm._v(_vm._s(v))])]);
        }) : _c('span', [_vm._v("Keine r4c Praxis-Info")])], 2)], 1)], 2)]) : _vm._e(), _c(VDialog, {
          attrs: {
            "scrollable": "",
            "max-width": "800px"
          },
          model: {
            value: _vm.newClientDialog,
            callback: function callback($$v) {
              _vm.newClientDialog = $$v;
            },
            expression: "newClientDialog"
          }
        }, [_c(VCard, [_c(VCardTitle, [_vm._v("Neuen Kunden anlegen")]), _c(VCardText, [_c('h4', [_vm._v("Kunde"), _vm.newClientValid ? _c(VIcon, {
          attrs: {
            "color": "success"
          }
        }, [_vm._v("checkmark")]) : _vm._e()], 1), _c('NewClientDialogComponent', {
          attrs: {
            "client": _vm.newClient
          },
          on: {
            "valid": function valid($event) {
              _vm.newClientValid = $event;
            },
            "update:client": _vm.updateClientData
          }
        }), _c('h4', {
          staticClass: "mt-5"
        }, [_vm._v("Erster Benutzer"), _vm.newUserValid ? _c(VIcon, {
          attrs: {
            "color": "success"
          }
        }, [_vm._v("checkmark")]) : _vm._e()], 1), _c('NewUserDialogComponent', {
          attrs: {
            "user": _vm.newUser,
            "hide-client-id-input": true
          },
          on: {
            "valid": function valid($event) {
              _vm.newUserValid = $event;
            }
          }
        })], 1), _c(VCardActions, [_c(VBtn, {
          attrs: {
            "color": "primary",
            "text": ""
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.closeNewClientDialog(false);
            }
          }
        }, [_vm._v("Abbrechen")]), _c(VSpacer), _c(VBtn, {
          attrs: {
            "color": "primary",
            "disabled": !_vm.newClientValid
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.closeNewClientDialog(true);
            }
          }
        }, [_vm._v("Anlegen")])], 1)], 1)], 1), _c('ResponseDialogComponent', {
          attrs: {
            "data": _vm.responseDialogData,
            "raw-response": _vm.rawResponse,
            "event-trigger": _vm.eventTrigger,
            "status": _vm.responseDialogStatus,
            "path": _vm.path
          }
        })];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };
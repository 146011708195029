import { RoseApi } from '../apiTypes';
import {
  CharlyGroupedTemplates,
  ICharlyDocSettings,
  ICharlySerializedRequest,
  IEventTargetDebugInfo,
  IFactoringAnfrage,
  IFactoringEWE,
  IProxiedCharlyRequestResult,
  QueryCharlySqlRequest,
} from '../../../../types';

export function createAdminApi(api: RoseApi) {
  return {
    getCharlyTemplates: (cid: string, r4chost: string) =>
      api.r4c.get<CharlyGroupedTemplates>(r4chost, `/admin/charlytemplatesdocs`, { clientIdHeader: cid }),
    getCharlyTemplateRtf: (cid: string, r4chost: string, group: string, entry: string) =>
      api.r4c.get<string>(r4chost, `/admin/charlytemplate/${group}/${entry}`, {
        clientIdHeader: cid,
      }),
    renderRtf: (
      rtf: string,
      cid: string,
      r4chost: string,
      filename: string,
      options?: { skipFixRtf?: boolean; overrideDocumentSettings?: any; overrideLineSpacingLUT?: any },
    ) =>
      api.r4c.post<{
        rtfUrl: string;
        pdfUrl: string;
        imageUrl: string;
        documentSettings: ICharlyDocSettings;
        fixedContent: string;
        referencedFiles: string[];
      }>(r4chost, `/admin/renderRtf`, {
        rtf,
        cid,
        filename,
        options,
      }),
    overrideCharlyTemplate: (cid: string, r4chost: string, group: string, entry: string, rtfText: string) =>
      api.r4c.post<void>(r4chost, `/admin/overrideCharlyTemplate`, {
        cid,
        group,
        entry,
        rtfText,
      }),
    restoreCharlyTemplate: (cid: string, r4chost: string, group: string, entry: string) =>
      api.r4c.post<void>(r4chost, `/admin/restoreCharlyTemplate`, {
        cid,
        group,
        entry,
      }),
    getRecentData: (cid: string) =>
      api.r4cDispatch.get<{ ewes: IFactoringEWE[]; anfragen: IFactoringAnfrage[] }>(`/admin/recentdata/${cid}`),
    retryuploadtask: (r4chost: string, id: string) => api.r4c.post(r4chost, `/factoringinit/retryuploadtask/${id}`),
    getFailedUploadsStats: (r4chost: string, params: { cid: string }) =>
      api.r4c.post<any>(r4chost, `/factoringinit/getFailedUploadsStats`, params),
    retryFailedUploads: (r4chost: string, params: { cid: string }) =>
      api.r4c.post<any>(r4chost, `/factoringinit/retryFailedUploads`, params),
    proxyCharlyRequest: ({
      r4chost,
      cid,
      request,
    }: {
      r4chost: string;
      cid: string;
      request: ICharlySerializedRequest;
    }) => api.r4c.post<IProxiedCharlyRequestResult>(r4chost, `/admin/proxyCharlyRequest`, { request, cid }),
    queryCharlySql: ({ r4chost, cid, request }: { r4chost: string; cid: string; request: QueryCharlySqlRequest }) =>
      api.r4c.post<{ success: true; response: any } | { success: false; error: any }>(
        r4chost,
        `/admin/queryCharlySql`,
        { request, cid },
      ),
    // admin debug
    getEventTargetDebugInfo: (r4chost: string) =>
      api.r4c.get<IEventTargetDebugInfo>(r4chost, `/admin-debug/events-debug`),
  };
}

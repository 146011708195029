import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VCard, {
    attrs: {
      "outlined": ""
    }
  }, [_c(VCardTitle, [_c('div', {
    staticClass: "card-title"
  }, [_vm._v("Trigger")])]), _c(VCardText, [_c(VContainer, {
    attrs: {
      "grid-list-md": "",
      "text-xs-center": "",
      "pa-0": ""
    }
  }, [_c(VLayout, {
    staticClass: "trigger-actions",
    attrs: {
      "row": "",
      "wrap": "",
      "align-center": "",
      "justify-center": ""
    }
  }, [_c(VFlex, [_c(VBtn, {
    attrs: {
      "block": "",
      "x-small": "",
      "color": "info"
    },
    on: {
      "click": _vm.triggerTerminbuch
    }
  }, [_vm._v("Terminbuch")])], 1), _c(VFlex, [_c(VBtn, {
    attrs: {
      "block": "",
      "x-small": "",
      "color": "info"
    },
    on: {
      "click": _vm.triggerUmsatz
    }
  }, [_vm._v("Umsatz")])], 1), _c(VFlex, [_c(VBtn, {
    attrs: {
      "block": "",
      "x-small": "",
      "color": "info",
      "title": "Leistungsblöcke"
    },
    on: {
      "click": _vm.triggerLeistungsbloecke
    }
  }, [_vm._v("LB")])], 1), _c(VFlex, [_c(VBtn, {
    attrs: {
      "block": "",
      "x-small": "",
      "color": "info"
    },
    on: {
      "click": _vm.triggerBenchmark
    }
  }, [_vm._v("Benchmark")])], 1), _c(VFlex, [_c(VBtn, {
    attrs: {
      "block": "",
      "x-small": "",
      "color": "info"
    },
    on: {
      "click": _vm.triggerLeistungsautomatik
    }
  }, [_vm._v("Leistungs-Auto")])], 1), _c(VFlex, [_c(VBtn, {
    attrs: {
      "block": "",
      "x-small": "",
      "color": "info"
    },
    on: {
      "click": _vm.triggerTodomailer
    }
  }, [_vm._v("Todomailer")])], 1), _c(VFlex, [_c(VBtn, {
    attrs: {
      "block": "",
      "x-small": "",
      "color": "info"
    },
    on: {
      "click": _vm.triggerHkpmailer
    }
  }, [_vm._v("Hkpmailer")])], 1), _c(VFlex, [_c(VBtn, {
    attrs: {
      "block": "",
      "x-small": "",
      "color": "info"
    },
    on: {
      "click": _vm.triggerHkp
    }
  }, [_vm._v("Hkp")])], 1), _c(VFlex, [_c(VBtn, {
    attrs: {
      "block": "",
      "x-small": "",
      "color": "info",
      "title": "Abrechnungsautomat"
    },
    on: {
      "click": _vm.triggerAbrechnungsautomat
    }
  }, [_vm._v("AA")])], 1), _c(VFlex, [_c(VBtn, {
    attrs: {
      "block": "",
      "x-small": "",
      "color": "info",
      "title": "PAR-Events"
    },
    on: {
      "click": _vm.triggerPA
    }
  }, [_vm._v("PA")])], 1), _c(VFlex, [_c(VBtn, {
    attrs: {
      "block": "",
      "x-small": "",
      "color": "info"
    },
    on: {
      "click": _vm.triggerMetaUpdate
    }
  }, [_vm._v("Update Meta")])], 1), _c(VFlex, [_c(VBtn, {
    attrs: {
      "block": "",
      "x-small": "",
      "color": "info"
    },
    on: {
      "click": _vm.triggerHeute
    }
  }, [_vm._v("Heute 5 Tage")])], 1), _c(VFlex, [_c('rose-confirm-button', {
    attrs: {
      "x-small": "",
      "actionText": "Transform Roh (Import)",
      "block": "",
      "disabled": !_vm.pvsCanTransformRohdaten
    },
    on: {
      "confirmed": _vm.triggerTransformRohdatenOnImportTables
    }
  }, [_vm._v("Transform Roh (Import)")])], 1), _c(VFlex, [_c('rose-confirm-button', {
    attrs: {
      "x-small": "",
      "actionText": "Transform Roh (Live)",
      "block": "",
      "disabled": !_vm.pvsCanTransformRohdaten
    },
    on: {
      "confirmed": _vm.triggerTransformRohdatenOnLiveTables
    }
  }, [_vm._v("Transform Roh (Live)")])], 1), _c(VFlex, [_c(VBtn, {
    attrs: {
      "block": "",
      "x-small": "",
      "color": "error"
    },
    on: {
      "click": _vm.triggerResetQueueFull
    }
  }, [_c('span', [_vm._v("Reset Queue Full")])])], 1), _c(VFlex, [_c(VBtn, {
    attrs: {
      "block": "",
      "x-small": "",
      "color": "warning"
    },
    on: {
      "click": _vm.triggerResetQueueIncremental
    }
  }, [_c('span', [_vm._v("Reset Queue Inc")])])], 1), _c(VFlex, [_c(VBtn, {
    attrs: {
      "block": "",
      "x-small": "",
      "color": "error"
    },
    on: {
      "click": _vm.triggerResetStateFull
    }
  }, [_c('span', [_vm._v("Reset State Full")])])], 1), _c(VFlex, [_c(VBtn, {
    attrs: {
      "block": "",
      "x-small": "",
      "color": "warning"
    },
    on: {
      "click": _vm.triggerResetStateIncremental
    }
  }, [_c('span', [_vm._v("Reset State Inc")])])], 1), _c(VFlex, [_c('rose-confirm-button', {
    attrs: {
      "x-small": "",
      "actionText": "Migrate DB",
      "block": ""
    },
    on: {
      "confirmed": _vm.triggerMigrateDb
    }
  }, [_vm._v("Migrate DB")])], 1), _c(VFlex, [_c('rose-confirm-button', {
    attrs: {
      "x-small": "",
      "actionText": "Rebuild DB",
      "block": ""
    },
    on: {
      "confirmed": _vm.triggerRebuildDb
    }
  }, [_vm._v("Rebuild DB")])], 1), _c(VFlex, [_c(VBtn, {
    attrs: {
      "block": "",
      "x-small": "",
      "color": "warning"
    },
    on: {
      "click": _vm.triggerIncrementalCollect
    }
  }, [_vm._v("Inc Import")])], 1), _c(VFlex, [_c('rose-confirm-button', {
    attrs: {
      "x-small": "",
      "actionText": "Full Import",
      "description": "Full Import starten?",
      "block": ""
    },
    on: {
      "confirmed": _vm.triggerFullCollect
    }
  }, [_vm._v("Full Import")])], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
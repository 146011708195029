import { hasAnyR4cFeature } from '@/../../base';
import {
  hostnameFormatter,
  binarySizeFormatter,
  durationFormatter,
  lastArrayFormatter,
  dateTimeFormatter,
  classHoursAlarmCss,
  fromNowWoSuffixFormatter,
  dateFormatter,
  IRoseAGGridColumn,
} from '@rose/common-ui';
import { IBillableBehandlerInfo, IClientDetails, IStripeMeta } from '@rose/types';

const getZaPzr = (params: any) => {
  const mb: IBillableBehandlerInfo = params.data?.meta.aktiveBehandler;
  if (mb?.za >= 0) {
    return `${mb.za} / ${mb.pzr} (${mb.billable} / ${mb.billableRounded})`;
  }
  return '';
};

const getStripeLicenceInfo = (params: any) => {
  const ms: IStripeMeta = params.data?.meta.stripe;
  if (ms.metricsType === 'volume') {
    return `v ${ms.metricsBehandlerLizenzen}`;
  }
  return `${ms.metricsBehandlerLizenzen}`;
};

const getPVS = (params: any) => {
  if (hasAnyR4cFeature(params.data)) {
    return 'charly';
  }
  return params.data?.meta.pvsInfo.typ;
};

const getPVSVersion = (params: any) =>
  (params.data?.meta.pvsInfo?.version && params.data?.meta.pvsInfo?.version !== '-'
    ? params.data?.meta.pvsInfo?.version
    : params.data?.metar4c.pvsInfo?.version) || '-';

const getLastActiveUsersCount = (params: any) => params.data.meta.lastActiveUsers.length;

export const metricsColumns = [
  'fullimportavg',
  'lastActive',
  'activeUsers',
  'lastOnline',
  'import',
  'uptime',
  'version',
  'ipExternal',
  'ipInternal',
  'monitor',
  'active',
  'user',
  'patienten',
  'termine',
];

export const r4cColumns = [
  'r4cPatienten',
  'r4cTermine',
  'r4cPatienten',
  'r4cTermine',
  'r4cHost',
  'r4cCount',
  'tablets',
  'r4cRz',
  'r4cVertraege',
  'r4cLastEwe',
  'r4cEweCount',
  'r4cClicks',
  'r4cSignedDocuments',
];

export function clientsColumns(smallScreen: boolean): IRoseAGGridColumn<IClientDetails>[] {
  return [
    {
      headerName: 'Client-ID',
      field: 'cid',
      cellRenderer: 'ClientsClientIdCell',
      sort: 'asc',
      headerTooltip: 'Eindeutige ID des Kunden',
      width: 350,
      pinned: smallScreen ? undefined : 'left',
    },
    {
      headerName: 'Users',
      valueGetter: params => params.data?.cid,
      cellRenderer: 'GoToUsersCell',
      cellStyle: { display: 'flex', 'justify-content': 'center', 'align-items': 'center' },
      width: 40,
    },
    { headerName: 'Praxis', field: 'praxisKurzName', cellRenderer: 'ClientsClientIdCell', width: 300 },
    { headerName: 'Tags', field: 'tags', cellRenderer: 'TagsCell', headerTooltip: 'Tags', width: 250 },
    {
      headerName: 'Kunde seit',
      field: 'createdAt',
      headerTooltip: 'Kunde seit',
      valueFormatter: dateFormatter,
      width: 100,
    },
    {
      headerName: 'Features',
      field: 'lizenzen',
      cellRenderer: 'FeaturesCell',
      headerTooltip: 'Features',
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: 'ON',
      field: 'bot.online',
      cellRenderer: 'BooleanIconCell',
      headerTooltip: 'Online (Bot)',
      width: 50,
    },
    {
      headerName: 'SSH',
      field: 'bot.sshonline',
      cellRenderer: 'BooleanIconCell',
      headerTooltip: 'Status SSH-Tunnel',
      width: 50,
    },
    {
      headerName: 'PVS',
      valueGetter: getPVS,
      cellRenderer: 'PvsCell',
      headerTooltip: 'Art des PVS',
      width: 70,
    },
    {
      headerName: 'ø Full',
      colId: 'fullimportavg',
      field: 'meta.fullImportStatistics.avg',
      valueFormatter: durationFormatter,
      headerTooltip: 'ø-Dauer Full-Import',
      width: 70,
    },
    {
      headerName: 'RZ',
      colId: 'r4cRz',
      field: 'rzkeys',
      cellRenderer: 'TagsCell',
      headerTooltip: 'Rechenzentren',
      width: 100,
    },

    {
      headerName: 'V-PVS',
      // field: 'meta.pvsInfo.version',
      valueGetter: getPVSVersion,
      headerTooltip: 'Version des PVS',
      width: 70,
    },
    {
      headerName: 'V-DB',
      field: 'dbInfo.version.short',
      headerTooltip: 'Version der Datenbank',
      width: 60,
    },
    { headerName: 'DB-Host', field: 'dbhost', valueFormatter: hostnameFormatter, headerTooltip: 'DB-Host', width: 90 },
    {
      headerName: 'SSH-Port',
      field: 'bot.sshPort',
      cellRenderer: 'CopyTextCell',
      headerTooltip: 'SSH-Port',
      width: 60,
    },
    {
      headerName: 'SSH-Alias',
      field: 'bot.alias',
      cellRenderer: 'CopyTextCell',
      headerTooltip: 'SSH-Alias',
      width: 120,
    },
    {
      headerName: 'Group',
      valueGetter: params => params.data?.groupdata?.name,
      cellRenderer: 'CopyTextCell',
      headerTooltip: 'Group',
      width: 100,
    },
    {
      headerName: 'Verträge',
      colId: 'r4cVertraege',
      field: 'rzvertraege',
      cellRenderer: 'VertraegeCell',
      headerTooltip: 'Verträge',
      width: 180,
    },
    {
      headerName: 'DB-Size',
      field: 'meta.dbInfo.size',
      valueFormatter: binarySizeFormatter,
      headerTooltip: 'DB-Size',
      width: 75,
    },
    { headerName: 'KZV', field: 'meta.screening.kzv', headerTooltip: 'KZV' },
    { headerName: 'BEL', field: 'meta.screening.laborbel', headerTooltip: 'BEL', width: 50 },
    { headerName: '#ZA/PZR', valueGetter: getZaPzr, headerTooltip: 'Anzahl ZA/PZR', width: 100 },
    {
      headerName: '#Stripe',
      valueGetter: getStripeLicenceInfo,
      comparator: (valueA, valueB, nodeA, nodeB) => {
        const msA = nodeA.data?.meta?.stripe?.metricsBehandlerLizenzen || -1;
        const msB = nodeB.data?.meta?.stripe?.metricsBehandlerLizenzen || -1;
        console.log('msA', msA, 'msB', msB);
        if (msA === msB) {
          const vA = nodeA.data?.meta?.stripe?.metricsType === 'volume';
          const vB = nodeB.data?.meta?.stripe?.metricsType === 'volume';
          if (vA === vB) {
            return 0;
          }
          return vA ? 1 : -1;
        }
        return msA - msB;
      },
      headerTooltip: 'Anzahl der Stripe-Behandler-Lizenzen',
      width: 60,
    },
    {
      headerName: 'Praxis-Location',
      valueGetter: (params: any) => !!params.data?.location?.osmid,
      cellRenderer: 'BooleanIconCell',
      headerTooltip: 'Praxis-Location',
      width: 40,
    },
    {
      headerName: 'Doctosync',
      valueGetter: (params: any) => params.data?.meta?.doctosync && !params.data?.meta?.doctosync?.error,
      cellRenderer: 'BooleanIconCell',
      headerTooltip: 'Doctosync-Aktiv',
      width: 50,
    },
    { headerName: '#r4cs', colId: 'r4cCount', field: 'countR4C', headerTooltip: '#r4cs', width: 100 },
    {
      headerName: '#Tablets',
      colId: 'tablets',
      field: 'registeredTabletCount',
      headerTooltip: 'r4c Anzahl Tablets',
      width: 100,
    },
    {
      headerName: 'last Active',
      colId: 'lastActive',
      field: 'meta.lastActiveUsers',
      valueFormatter: lastArrayFormatter,
      headerTooltip: 'Zuletzt Aktiv',
      width: 125,
    },
    {
      headerName: 'Last Online',
      colId: 'lastOnline',
      field: 'bot.updatedAt',
      valueFormatter: dateTimeFormatter,
      cellClass: (params: any) => classHoursAlarmCss(params.value, 1, 'bold-red'),
      headerTooltip: 'Zuletzt Online',
      width: 135,
    },
    {
      headerName: 'Import',
      colId: 'import',
      field: 'importInfos',
      cellRenderer: 'ClientImportCell',
      headerTooltip: 'Import',
      width: 70,
    },
    {
      headerName: 'Uptime',
      colId: 'uptime',
      field: 'bot.uptime',
      valueFormatter: fromNowWoSuffixFormatter,
      cellClass: (params: any) => classHoursAlarmCss(params.value, 24, 'bold-red', true),
      headerTooltip: 'Uptime Bot',
      width: 90,
    },
    {
      headerName: 'V-Bot',
      colId: 'version',
      field: 'bot.version',
      cellRenderer: 'BotVersionCell',
      headerTooltip: 'Bot-Version',
      width: 135,
    },
    {
      headerName: 'IP Extern',
      colId: 'ipExternal',
      field: 'bot.ipExternal',
      cellRenderer: 'CopyTextCell',
      headerTooltip: 'IP Extern',
      width: 120,
    },
    {
      headerName: 'IP Intern',
      colId: 'ipInternal',
      field: 'bot.ipInternal',
      cellRenderer: 'CopyTextCell',
      headerTooltip: 'IP Intern',
      width: 120,
    },
    {
      headerName: 'Monitor',
      colId: 'monitor',
      field: 'bot.monitor',
      cellRenderer: 'BooleanIconCell',
      headerTooltip: 'Monitor',
      width: 50,
    },
    {
      headerName: 'Active',
      colId: 'active',
      field: 'bot.active',
      cellRenderer: 'BooleanIconCell',
      headerTooltip: 'Aktiv',
      width: 50,
    },
    { headerName: '#User', colId: 'user', field: 'meta.anzahlBenutzer', headerTooltip: 'Anzahl User', width: 50 },
    {
      headerName: '#Patienten',
      colId: 'patienten',
      field: 'meta.anzahlPatienten',
      headerTooltip: 'Anzahl Patienten',
      width: 50,
    },
    {
      headerName: '#Termine',
      colId: 'termine',
      field: 'meta.anzahlTermine',
      headerTooltip: 'Anzahl Termine',
      width: 50,
    },
    {
      headerName: '#P (r4c)',
      colId: 'r4cPatienten',
      field: 'metar4c.anzahlPatienten',
      headerTooltip: 'r4c Anzahl Patienten',
      width: 70,
    },
    {
      headerName: '#T (r4c)',
      colId: 'r4cTermine',
      field: 'metar4c.anzahlTermine',
      headerTooltip: 'r4c Anzahl Termine',
      width: 70,
    },
    {
      headerName: 'r4cHost',
      colId: 'r4cHost',
      field: 'r4chost',
      valueFormatter: hostnameFormatter,
      headerTooltip: 'r4c Host',
      width: 70,
    },
    {
      headerName: 'Letzte ewe',
      colId: 'r4cLastEwe',
      field: 'r4cStats.lastewe',
      valueFormatter: dateFormatter,
      headerTooltip: 'Letzte EWE',
      width: 100,
    },
    {
      headerName: '#ewe',
      colId: 'r4cEweCount',
      field: 'r4cStats.ewecount',
      headerTooltip: 'r4c Anzahl EWE',
      width: 60,
    },
    {
      headerName: '#clicks',
      colId: 'r4cClicks',
      field: 'r4cStats.clicks',
      headerTooltip: 'r4c Anzahl Clicks',
      width: 60,
    },
    {
      headerName: '#docs',
      colId: 'r4cSignedDocuments',
      field: 'r4cStats.signeddocumentcount',
      headerTooltip: '#docs',
      width: 60,
    },
  ];
}

import "core-js/modules/es.object.keys.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "wrap"
  }, [_c('h2', [_vm._v("SSE Events")]), _vm._l(Object.keys(_vm.eventDebugInfo), function (server) {
    return [_c('h3', {
      staticClass: "mt-4"
    }, [_vm._v(_vm._s(server))]), _vm.eventDebugInfo[server] ? _c('div', {
      staticClass: "events"
    }, _vm._l(_vm.eventDebugInfo[server], function (event) {
      return _c('div', {
        staticClass: "event"
      }, [_c('div', {
        staticClass: "eventName"
      }, [_c('span', [_vm._v(_vm._s(event.path))]), _vm._m(0, true), _vm._l(event.params, function (param) {
        return _c('span', {
          staticClass: "param"
        }, [_c('span', [_vm._v("#")]), _c('span', {
          staticClass: "paramTag"
        }, [_vm._v(_vm._s(param))])]);
      })], 2), _c('div', {
        staticClass: "cache"
      }, _vm._l(_vm.getActiveParamsForEvent(event), function (subEvent) {
        return _c('div', {
          staticClass: "cacheItem"
        }, [_c('div', {
          staticClass: "cacheItemName"
        }, [_c('div', {
          staticClass: "mr-1"
        }, [_vm._v(_vm._s(subEvent) + " (")]), _c('div', {
          staticClass: "subscriberCount mr-1"
        }, [_vm._v(_vm._s(event.subscribers[subEvent] || 0))]), _c('div', {
          staticClass: "mr-1"
        }, [_vm._v("subscribers)")])]), event.cacheContent ? _c('div', {
          staticClass: "cacheItemContent"
        }, [_c('details', [_c('summary', [_vm._v(_vm._s(_vm.truncateString(event.cacheContent[subEvent])))]), _c('pre', [_vm._v(_vm._s(event.cacheContent[subEvent]))])])]) : _vm._e()]);
      }), 0)]);
    }), 0) : _vm._e()];
  })], 2);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('span', {
    staticClass: "param"
  }, [_c('span', [_vm._v("/")]), _c('span', {
    staticClass: "paramTag"
  }, [_vm._v("cid")])]);
}];
export { render, staticRenderFns };
import "core-js/modules/es.array.includes.js";
import { triggerIncrementalCollect as _triggerIncrementalCollect, triggerFullCollect as _triggerFullCollect, triggerMigrateDb as _triggerMigrateDb, triggerRebuildDb as _triggerRebuildDb, triggerTerminbuch as _triggerTerminbuch, triggerTransformRohdaten, triggerUmsatz as _triggerUmsatz, triggerLeistungsbloecke as _triggerLeistungsbloecke, triggerBenchmark as _triggerBenchmark, triggerLeistungsautomatik as _triggerLeistungsautomatik, triggerTodomailer as _triggerTodomailer, triggerMetaUpdate as _triggerMetaUpdate, triggerHeute as _triggerHeute, triggerHkp as _triggerHkp, triggerAbrechnungsautomat as _triggerAbrechnungsautomat, triggerHkpmailer as _triggerHkpmailer, triggerPA as _triggerPA, triggerResetQueueFull as _triggerResetQueueFull, triggerResetQueueIncremental as _triggerResetQueueIncremental, triggerResetStateIncremental as _triggerResetStateIncremental, triggerResetStateFull as _triggerResetStateFull } from '@/services/data';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'ClientTrigger',
  props: {
    isSolutioTeam: Boolean,
    selectedClient: {
      type: Object,
      "default": null
    }
  },
  data: function data() {
    return {
      heuteDaysBack: 5
    };
  },
  computed: {
    pvs: function pvs() {
      return this.selectedClient.pvs;
    },
    pvsCanTransformRohdaten: function pvsCanTransformRohdaten() {
      return ['evident', 'z1v2'].includes(this.pvs);
    }
  },
  methods: {
    triggerIncrementalCollect: function triggerIncrementalCollect() {
      var _this = this;
      this.$emit('handleApiCallWithResponseStatusInfo', function () {
        var _a;
        return _triggerIncrementalCollect((_a = _this.selectedClient) === null || _a === void 0 ? void 0 : _a.cid, true);
      }, 'Update Sammlung gestartet');
    },
    triggerFullCollect: function triggerFullCollect() {
      var _this2 = this;
      this.$emit('handleApiCallWithResponseStatusInfo', function () {
        var _a;
        return _triggerFullCollect((_a = _this2.selectedClient) === null || _a === void 0 ? void 0 : _a.cid, true);
      }, 'Komplett Sammlung gestartet');
    },
    triggerResetQueueFull: function triggerResetQueueFull() {
      var _this3 = this;
      this.$emit('handleApiCallWithResponseStatusInfo', function () {
        var _a;
        return _triggerResetQueueFull((_a = _this3.selectedClient) === null || _a === void 0 ? void 0 : _a.cid);
      }, 'Queue für Full-Import geleert');
    },
    triggerResetQueueIncremental: function triggerResetQueueIncremental() {
      var _this4 = this;
      this.$emit('handleApiCallWithResponseStatusInfo', function () {
        var _a;
        return _triggerResetQueueIncremental((_a = _this4.selectedClient) === null || _a === void 0 ? void 0 : _a.cid);
      }, 'Queue für Incremental-Import geleert');
    },
    triggerResetStateIncremental: function triggerResetStateIncremental() {
      var _this5 = this;
      this.$emit('handleApiCallWithResponseStatusInfo', function () {
        var _a;
        return _triggerResetStateIncremental((_a = _this5.selectedClient) === null || _a === void 0 ? void 0 : _a.cid);
      }, 'State für Incremental-Import zurückgesetzt');
    },
    triggerResetStateFull: function triggerResetStateFull() {
      var _this6 = this;
      this.$emit('handleApiCallWithResponseStatusInfo', function () {
        var _a;
        return _triggerResetStateFull((_a = _this6.selectedClient) === null || _a === void 0 ? void 0 : _a.cid);
      }, 'State für Full-Import zurückgesetzt');
    },
    triggerRebuildDb: function triggerRebuildDb() {
      var _this7 = this;
      this.$emit('handleApiCallWithResponseStatusInfo', function () {
        var _a;
        return _triggerRebuildDb((_a = _this7.selectedClient) === null || _a === void 0 ? void 0 : _a.cid, true);
      }, 'Rebuild DB Trigger');
    },
    triggerMigrateDb: function triggerMigrateDb() {
      var _this8 = this;
      this.$emit('handleApiCallWithResponseStatusInfo', function () {
        var _a;
        return _triggerMigrateDb((_a = _this8.selectedClient) === null || _a === void 0 ? void 0 : _a.cid, true);
      }, 'Migrate DB Trigger');
    },
    triggerTransformRohdatenOnImportTables: function triggerTransformRohdatenOnImportTables() {
      var _this9 = this;
      this.$emit('handleApiCallWithResponseStatusInfo', function () {
        var _a;
        return triggerTransformRohdaten((_a = _this9.selectedClient) === null || _a === void 0 ? void 0 : _a.cid, true, true);
      }, 'Transform Rohdaten Trigger On Import Tables');
    },
    triggerTransformRohdatenOnLiveTables: function triggerTransformRohdatenOnLiveTables() {
      var _this10 = this;
      this.$emit('handleApiCallWithResponseStatusInfo', function () {
        var _a;
        return triggerTransformRohdaten((_a = _this10.selectedClient) === null || _a === void 0 ? void 0 : _a.cid, false, true);
      }, 'Transform Rohdaten Trigger On Live Tables');
    },
    triggerTerminbuch: function triggerTerminbuch() {
      var _this11 = this;
      this.$emit('handleApiCallWithResponseStatusInfo', function () {
        var _a;
        return _triggerTerminbuch((_a = _this11.selectedClient) === null || _a === void 0 ? void 0 : _a.cid, true);
      }, 'Terminbuch Trigger');
    },
    triggerUmsatz: function triggerUmsatz() {
      var _this12 = this;
      this.$emit('handleApiCallWithResponseStatusInfo', function () {
        var _a;
        return _triggerUmsatz((_a = _this12.selectedClient) === null || _a === void 0 ? void 0 : _a.cid, true);
      }, 'Umsatz Trigger');
    },
    triggerLeistungsbloecke: function triggerLeistungsbloecke() {
      var _this13 = this;
      this.$emit('handleApiCallWithResponseStatusInfo', function () {
        var _a;
        return _triggerLeistungsbloecke((_a = _this13.selectedClient) === null || _a === void 0 ? void 0 : _a.cid, true);
      }, 'Leistungsbloecke Trigger');
    },
    triggerBenchmark: function triggerBenchmark() {
      var _this14 = this;
      this.$emit('handleApiCallWithResponseStatusInfo', function () {
        var _a;
        return _triggerBenchmark((_a = _this14.selectedClient) === null || _a === void 0 ? void 0 : _a.cid, true);
      }, 'Benchmark Trigger');
    },
    triggerLeistungsautomatik: function triggerLeistungsautomatik() {
      var _this15 = this;
      this.$emit('handleApiCallWithResponseStatusInfo', function () {
        var _a;
        return _triggerLeistungsautomatik((_a = _this15.selectedClient) === null || _a === void 0 ? void 0 : _a.cid);
      }, 'Leistungs-Automatik Trigger');
    },
    triggerTodomailer: function triggerTodomailer() {
      this.$emit('handleApiCallWithResponseStatusInfo', function () {
        return _triggerTodomailer(true);
      }, 'Todomailer Trigger');
    },
    triggerMetaUpdate: function triggerMetaUpdate() {
      var _this16 = this;
      this.$emit('handleApiCallWithResponseStatusInfo', function () {
        var _a;
        return _triggerMetaUpdate((_a = _this16.selectedClient) === null || _a === void 0 ? void 0 : _a.cid);
      }, 'Update Meta Trigger');
    },
    triggerHeute: function triggerHeute() {
      var _this17 = this;
      this.$emit('handleApiCallWithResponseStatusInfo', function () {
        var _a;
        return _triggerHeute((_a = _this17.selectedClient) === null || _a === void 0 ? void 0 : _a.cid, _this17.heuteDaysBack, true);
      }, 'Heute Trigger');
    },
    triggerHkp: function triggerHkp() {
      var _this18 = this;
      this.$emit('handleApiCallWithResponseStatusInfo', function () {
        var _a;
        return _triggerHkp((_a = _this18.selectedClient) === null || _a === void 0 ? void 0 : _a.cid, true);
      }, 'triggerHkp');
    },
    triggerAbrechnungsautomat: function triggerAbrechnungsautomat() {
      var _this19 = this;
      this.$emit('handleApiCallWithResponseStatusInfo', function () {
        var _a;
        return _triggerAbrechnungsautomat((_a = _this19.selectedClient) === null || _a === void 0 ? void 0 : _a.cid, true);
      }, 'Abrechnungsautomat Trigger');
    },
    triggerPA: function triggerPA() {
      var _this20 = this;
      this.$emit('handleApiCallWithResponseStatusInfo', function () {
        var _a;
        return _triggerPA((_a = _this20.selectedClient) === null || _a === void 0 ? void 0 : _a.cid, true);
      }, 'PA Trigger');
    },
    triggerHkpmailer: function triggerHkpmailer() {
      this.$emit('handleApiCallWithResponseStatusInfo', function () {
        return _triggerHkpmailer(true);
      }, 'triggerHkpmailer');
    }
  }
});
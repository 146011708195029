import {
  IHVMPatientDetails,
  IHVMQueryParams,
  IHVMWestfalenLippeParams,
  IHVMVerlauf,
  IHVMResultNordrhein,
  IHVMResultBayern,
  IHVMResultNiedersachsen,
  IHVMResultWestfalenLippe,
} from '../../../../types';
import { RoseApi } from '../apiTypes';

export function createHVMApi(api: RoseApi) {
  return {
    getHVMNordrheinVerlauf: (queryParams?: IHVMQueryParams) =>
      api.metrics.get<IHVMVerlauf>(`/api/hvm/nordrhein/verlauf`, {
        query: queryParams,
      }),
    getHVMNordrhein: (queryParams?: IHVMQueryParams) =>
      api.metrics.get<IHVMResultNordrhein>(`/api/hvm/nordrhein`, {
        query: queryParams,
      }),
    getHVMNordrheinDetails: (
      von: string,
      bis: string,
      altervon: number,
      alterbis: number,
      ignorekzv: boolean,
      refType: string | undefined,
      part: string,
      partdetail: string,
      behandlerId?: string,
    ) =>
      api.metrics.post<IHVMPatientDetails>(`/api/hvm/nordrhein/details`, {
        von,
        bis,
        altervon,
        alterbis,
        ignorekzv,
        part,
        refType,
        partdetail,
        behandlerId,
      }),

    getHVMNiedersachsenVerlauf: (queryParams?: IHVMQueryParams) =>
      api.metrics.get<IHVMVerlauf>(`/api/hvm/nordrhein/verlauf`, {
        query: queryParams,
      }),
    getHVMNiedersachsen: (queryParams?: IHVMQueryParams) =>
      api.metrics.get<IHVMResultNiedersachsen>(`/api/hvm/niedersachsen`, {
        query: queryParams,
      }),
    getHVMNiedersachsenDetails: (
      von: string,
      bis: string,
      altervon: number,
      alterbis: number,
      ignorekzv: boolean,
      refType: string | undefined,
      part: string,
      partdetail: string,
      behandlerId?: string,
    ) =>
      api.metrics.post<IHVMPatientDetails>(`/api/hvm/niedersachsen/details`, {
        von,
        bis,
        altervon,
        alterbis,
        ignorekzv,
        part,
        refType,
        partdetail,
        behandlerId,
      }),

    getHVMBayern: (queryParams?: IHVMQueryParams) =>
      api.metrics.get<IHVMResultBayern>(`/api/hvm/bayern`, {
        query: queryParams,
      }),
    getHVMBayernPatientDetails: (
      von: string,
      bis: string,
      altervon: number,
      alterbis: number,
      ignorekzv: boolean,
      part: string,
      fallgruppe: string,
      partdetail: string,
      behandlerId?: string,
    ) =>
      api.metrics.post<IHVMPatientDetails>(`/api/hvm/bayern/details`, {
        von,
        bis,
        altervon,
        alterbis,
        ignorekzv,
        part,
        fallgruppe,
        partdetail,
        behandlerId,
      }),

    getHVMWestfalenLippe: (params?: IHVMWestfalenLippeParams) =>
      api.metrics.post<IHVMResultWestfalenLippe>(`/api/hvm/westfalen-lippe`, params),
    getHVMWestfalenLippeDetails: (
      von: string,
      bis: string,
      altervon: number,
      alterbis: number,
      ignorekzv: boolean,
      teil: string,
      row: string,
      fallgruppe: string,
      behandlerId?: string,
    ) =>
      api.metrics.post<IHVMPatientDetails>(`/api/hvm/westfalen-lippe/details`, {
        von,
        bis,
        altervon,
        alterbis,
        ignorekzv,
        teil,
        row,
        fallgruppe,
        behandlerId,
      }),
  };
}
